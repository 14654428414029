<script>
    import { Doughnut } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        data() {
            return {
                doughnutChartData:{
                    labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                    datasets: [
                        {
                        backgroundColor: [
                            'rgba(65, 184, 131, .8)',
                            'rgba(228, 102, 81, .8)',
                            'rgba(0, 216, 255, .8)',
                            'rgba(155, 89, 182, .8)'
                        ],
                        borderWidth: 0,
                        data: [40, 20, 80, 10]
                        }
                    ]
                },
            }
        },
        props: {
            data: {
                type: Object,
                default: null
            },
            options: {
                type: Object,
                default: null
            }
        },
        watch: {
            data: {
                handler: function() {
                    this.$data._chart.destroy();
                    this.renderChart(this.data, this.options)
                },
                deep: true
            },
        },
        mounted () {
            this.renderChart((this.data != null) ? this.data : this.doughnutChartData, this.options)
        }
    }
</script>
