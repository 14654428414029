<template>
    <div class="mychart bg-white p-2">
            <div class="d-flex bd-highlight border-bottom pt-2">
            <div class="flex-grow-1 bd-highlight chrttext">{{$t('message.order_count')}}: <span class="charminitext"> {{ totalSum }} </span></div>
            <div class="bd-highlight">
                <el-radio-group v-model="radio" size="mini">
                    <el-radio-button value="weekly" label="weekly">{{$t('message.week')}}</el-radio-button>
                    <el-radio-button value="monthly" label="monthly">{{$t('message.month')}}</el-radio-button>
                    <el-radio-button value="yearly" label="yearly">{{$t('message.year')}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div id="chart">
            <apexchart type="line" height="390" :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    export default {
        components: {},
        data() {
            return {
                totalSum: 0,
                radio: 'weekly',
                series: [{
                    name: 'Количество',
                    data: []
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {show: false}
            },
            tooltip: {
                enabled: true,
                shared: false,
                marker: {
                    show: false
                }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight',
              lineCap: 'butt',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: [],
            }
          },
          
            }
        },
        watch: {
            radio: {
                handler: function () {
                    this.fetchData();
                },
                deep: true
            },
        },
        created(){
            this.fetchData()
        },
        methods:{
            ...mapActions({
                editChart : 'orders/chartOrderCount'
            }),
            fetchData(){
                this.editChart(this.radio)
                    .then(res => {
                        if (res.chart_data){
                            this.chartOptions.xaxis.categories = [];
                            this.series[0].data = [];
                            this.totalSum = 0;
                            let labels = [], data = [];
                            for (let key in res.chart_data){
                                if (res.chart_data.hasOwnProperty(key)){
                                    let chart_data = res.chart_data[key];
                                    labels.push(chart_data.label);
                                    data.push({x: chart_data.label, y: chart_data.count});
                                    this.totalSum += chart_data.count;
                                }
                            }
                           
                            this.chartOptions.xaxis = {
                                categories: labels
                            };
                            this.series = [
                                {
                                    data: data
                                }
                            ];
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
</script>