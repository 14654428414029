<script>
    import { Bar } from 'vue-chartjs'

    export default {
        extends: Bar,
        data() {
            return {
                barChartData:{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'GitHub Commits',
                            backgroundColor: '#f87979',
                            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                        }
                    ]
                },
            }
        },
        props: {
            data: {
                type: Object,
                default: null,
            },
            options: {
                type: Object,
                default: null
            }
        },
        watch: {
            data: {
                handler: function() {
                    this.$data._chart.destroy();
                    this.renderChart(this.data, this.options)
                },
                deep: true
            },
        },
        mounted () {
            this.renderChart((this.data != null) ? this.data : this.barChartData, this.options)
        }
    }
</script>
